import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";
import { Link } from "react-router-dom";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 px-6 flex`}
`;

const StyledLink = styled(Link)`
  ${tw`flex flex-col mx-auto max-w-xs h-full items-center no-underline`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs h-full items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12 transition-transform duration-500 ease-in-out`}
  
  &:hover {
    ${tw`border-primary-500 border-solid border-4`}
  }

  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      to:"cocuksagligi",
      title: "Çocuk Sağlığı",
      description: "Uzman doktor olarak, çocuk sağlığı ve hastalıkları alanında kapsamlı sağlık hizmetleri sunuyorum. Doğumdan ergenliğe kadar çocukların fiziksel, duygusal ve sosyal sağlığını korumak için tanı, tedavi ve takip işlemlerini gerçekleştiriyorum. Aşılamalar, büyüme-gelişme takibi ve kronik hastalık yönetimi konularında deneyimimle çocuklarınıza en iyi bakımı sağlamaktayım."
    },
    {
      imageSrc: ShieldIconImage,
      to:"cocukbobrekhastaliklari",
      title: "Çocuk Böbrek Hastalıkları",
      description: "Çocuk böbrek hastalıkları uzmanı olarak, doğumdan ergenliğe kadar çocukların böbrek sağlığını korumak için tanı, tedavi ve takip hizmetleri sunuyorum. Böbrek enfeksiyonları, idrar yolu problemleri ve kronik böbrek hastalıkları konusunda uzmanlaşmış olup, çocuklarınızın sağlığını en üst düzeyde tutmayı hedefliyorum."
    },
    {
      imageSrc: ShieldIconImage,
      to:"cocukromatizmahastaliklari",
      title: "Çocuk Romatizma Hastalıkları",
      description: "Çocuk romatizma hastalıkları uzmanı olarak, çocukların eklem ve bağışıklık sistemi sağlığını desteklemek için tanı, tedavi ve takip işlemlerini gerçekleştiriyorum. Juvenil romatoid artrit, lupus ve diğer romatizmal hastalıklar konusunda deneyimimle, çocuklarınıza en etkili ve kapsamlı bakımı sunuyorum."
    },
    {
      imageSrc: ShieldIconImage,
      to:"epigenetikdanismanlik",
      title: "Epigenetik Danışmanlık",
      description: "Epigenetik danışmanlık alanında uzman doktor olarak, genetik ve çevresel faktörlerin çocukların sağlığı üzerindeki etkilerini değerlendiriyorum. Epigenetik değişikliklerin yönetimi ve genetik risklerin azaltılması için bireyselleştirilmiş tanı, tedavi ve takip hizmetleri sunuyorum, böylece çocuklarınıza sağlıklı bir gelecek sağlıyorum."
    },
    {
      imageSrc: ShieldIconImage,
      to:"fonksiyoneltip",
      title: "Fonksiyonel Tıp",
      description: "Fonksiyonel tıp uzmanı olarak, çocukların genel sağlığını optimize etmek için bütüncül ve bireyselleştirilmiş sağlık hizmetleri sunuyorum. Beslenme, yaşam tarzı değişiklikleri ve doğal tedavi yöntemleri ile çocuklarınızın sağlık sorunlarını kökten çözmeyi amaçlıyorum."
    },
    {
      imageSrc: ShieldIconImage,
      to:"fitoterapi",
      title: "Fitoterapi",
      description: "Fitoterapi alanında uzman doktor olarak, çocukların sağlık sorunlarını bitkisel tedavi yöntemleri ile çözmek için kapsamlı hizmetler sunuyorum. Güvenilir ve etkili bitkisel ilaçlarla, çocuklarınızın sağlığını doğal yöntemlerle destekliyorum."
    },
    {
      imageSrc: ShieldIconImage,
      to:"ayurvedikdanismanlik",
      title: "Ayurvedik Danışmanlık",
      description: "Ayurvedik danışmanlık hizmetleri sunan bir uzman olarak, çocukların dengeli ve sağlıklı bir yaşam sürmelerini desteklemek için ayurvedik prensipler ve doğal tedavi yöntemleri kullanıyorum. Bireyselleştirilmiş tanı ve tedavi planlarıyla, çocuklarınızın sağlığını doğal yollarla iyileştiriyorum."
    },
    {
      imageSrc: ShieldIconImage,
      to:"quexedkuantumdanismanlik",
      title: "QUEX ED Kuantum Danışmanlık",
      description: "QUEX ED Kuantum Danışmanlık uzmanı olarak, çocukların fiziksel ve enerji sağlığını dengelemek için kuantum biyofeedback yöntemleri kullanıyorum. Enerji dengesizliklerini gidermeye yönelik bu ileri teknolojiyle, çocuklarınıza modern ve etkili sağlık çözümleri sunuyorum."
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Sunduğum Uzman <span tw="text-primary-500">Hizmetler</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <StyledLink to={'/services/' + card.to}>
            <Card >
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
            </StyledLink>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};

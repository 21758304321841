import React from "react";
import {Navigation} from './Routes'
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/headers/light'
import Footer from "./components/footers/MiniCenteredFooter"
import HelloWorld from "components/HelloWorld";

function App() {

  return (
    <div className="App">
      <Router>
        {/* <HelloWorld/> */} 
        <Header/>
        <Navigation/>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;

import { SectionHeading } from 'components/misc/Headings';
import React from 'react';
import { useParams } from 'react-router';
import tw from 'twin.macro';
import styled from "styled-components";
import ScrollToTop from "components/misc/ScrollToTop";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../images/svg-decorator-blob-3.svg"     

const servicesList = {
  cocuksagligi: {title: "Çocuk Sağlığı", 
    imgSrc:'/assets/images/cocuksagligi.jpg', 
    description:`Çocuk sağlığı uzmanlığı, çocukların fiziksel, zihinsel ve duygusal sağlıklarını koruma, geliştirme ve iyileştirme amacı taşıyan tıbbi bir alandır. Bu uzmanlık dalı, doğumdan ergenlik dönemine kadar olan çocukların sağlık ihtiyaçlarına odaklanır ve onların sağlıklı bir şekilde büyüme ve gelişmelerini sağlamak için gerekli olan tanı, tedavi ve önleme hizmetlerini sunar. Çocuk sağlığı uzmanları, bebeklerin, çocukların ve ergenlerin sağlık sorunlarını erken dönemde tespit ederek, zamanında müdahaleler yapar ve uygun tedavi planları oluştururlar.

Bu uzmanlık alanında çalışan pediatristler, çocukların enfeksiyon hastalıklarından, kronik hastalıklara kadar geniş bir yelpazede sağlık sorunlarını yönetirler. Aşılamalar, düzenli sağlık kontrolleri ve gelişimsel değerlendirmeler, pediatristlerin rutin uygulamaları arasındadır. Ayrıca, beslenme, uyku düzeni ve fiziksel aktivite gibi konularda ailelere rehberlik ederler. Çocuk sağlığı uzmanları, çocukların ruhsal sağlıklarını da önemser ve bu doğrultuda, gerektiğinde çocuk psikologları ve diğer uzmanlarla işbirliği yaparak bütüncül bir yaklaşım benimserler.

Pediatri uzmanları, çocukların sağlık sorunlarını tedavi etmenin yanı sıra, sağlık eğitimleri ve koruyucu sağlık hizmetleri sunarak toplum sağlığını da desteklerler. Sağlıklı yaşam alışkanlıkları kazandırmak, çocukların gelecekteki sağlıklarını olumlu yönde etkiler. Çocuk sağlığı uzmanları, ailelerin bilinçli ve bilgili olmalarını sağlamak için eğitimler düzenler, bilgilendirici materyaller sunar ve toplumsal farkındalık oluşturur. Bu sayede, çocukların sağlıklı bir şekilde büyümeleri ve potansiyellerini en iyi şekilde gerçekleştirmeleri hedeflenir.`
},


  cocukbobrekhastaliklari: {title:"Çocuk Böbrek Hastalıkları", 
    imgSrc:'/assets/images/cocukbobrekhastaliklari.webp', 
    description:`
    Çocuk böbrek hastalıkları, çocukların böbreklerinde meydana gelen ve çeşitli sağlık sorunlarına yol açabilen durumları kapsar. Bu hastalıklar doğuştan gelen anormallikler, enfeksiyonlar, bağışıklık sistemi bozuklukları ve genetik yatkınlıklar gibi çeşitli nedenlerden kaynaklanabilir. Çocuk böbrek hastalıklarının erken teşhisi ve tedavisi, çocukların sağlıklı bir şekilde büyüme ve gelişmelerini sağlamak açısından büyük önem taşır. Böbreklerin fonksiyonlarını etkileyen hastalıklar arasında idrar yolu enfeksiyonları, nefrotik sendrom, kronik böbrek hastalığı ve akut böbrek yetmezliği bulunur.
    
    Çocuk böbrek hastalıklarının tanısında, detaylı tıbbi öykü, fizik muayene ve laboratuvar testleri önemli rol oynar. Kan ve idrar testleri, böbrek fonksiyonlarını değerlendirmek ve olası enfeksiyonları tespit etmek için kullanılır. Görüntüleme yöntemleri, böbreklerin yapısal sorunlarını belirlemek amacıyla uygulanır. Bu testler ve yöntemler sayesinde, çocuk nefroloji uzmanları hastalığın türünü ve ciddiyetini belirleyerek uygun tedavi planlarını oluştururlar. Tedavi süreci, hastalığın türüne ve şiddetine bağlı olarak ilaç tedavisi, diyet değişiklikleri ve gerektiğinde cerrahi müdahaleleri içerebilir.
    
    Erken teşhis ve tedavi, böbrek hastalıklarının ilerlemesini önlemek ve komplikasyonları azaltmak için kritiktir. Ebeveynlerin çocuklarının sağlık durumunu yakından takip etmeleri ve idrar renginde değişiklik, sık idrara çıkma, idrarda kan veya şişlik gibi belirtiler gördüklerinde hemen bir uzmana başvurmaları önemlidir. Çocuk böbrek hastalıkları uzmanları, ailelere hastalık yönetimi konusunda rehberlik eder ve çocukların yaşam kalitesini artırmak için gerekli önlemleri alır. Ayrıca, çocukların sağlıklı beslenme alışkanlıkları edinmeleri, düzenli fiziksel aktivite yapmaları ve yeterli sıvı tüketmeleri konusunda bilgilendirme yaparak, böbrek sağlığını korumaya yönelik önlemler alınmasını sağlarlar.`
},

  cocukromatizmahastaliklari: {title:"Çocuk Romatizma Hastalıkları",
    imgSrc:'/assets/images/cocukromatizmahastaliklari.webp',
    description:`Çocuk romatizma hastalıkları, çocukların eklemlerini, kaslarını ve bağ dokularını etkileyen kronik inflamatuar durumlardır. Bu hastalıklar genellikle otoimmün bir doğaya sahiptir, yani bağışıklık sistemi yanlışlıkla vücudun kendi dokularına saldırır. Çocuk romatizma hastalıkları arasında en yaygın olanı juvenil idiopatik artrit (JIA) olup, bu hastalık eklemlerde ağrı, şişlik ve hareket kısıtlılığına neden olabilir. Diğer çocuk romatizma hastalıkları arasında lupus, dermatomiyozit ve skleroderma bulunur.

    Juvenil idiopatik artrit (JIA) gibi çocuk romatizma hastalıklarının tanısında, detaylı tıbbi öykü, fizik muayene ve çeşitli laboratuvar testleri önemlidir. Kan testleri, inflamasyon seviyelerini ölçmek ve otoantikorları tespit etmek için kullanılırken, görüntüleme yöntemleri eklemlerdeki hasarı değerlendirmek için uygulanır. Bu testler, hastalığın türünü ve ciddiyetini belirlemeye yardımcı olur. Tedavi planı, hastalığın şiddetine ve çocuğun bireysel ihtiyaçlarına bağlı olarak belirlenir. İlaç tedavisi, hastalığın kontrol altına alınmasında önemli bir rol oynar ve genellikle nonsteroid antiinflamatuar ilaçlar (NSAID'ler), hastalığı modifiye edici antiromatizmal ilaçlar (DMARD'ler) ve biyolojik ajanları içerir.
    
    Çocuk romatizma hastalıkları, çocukların yaşam kalitesini önemli ölçüde etkileyebilir. Bu nedenle, erken teşhis ve uygun tedavi çok önemlidir. Fizik tedavi ve ergoterapi, eklem hareketliliğini korumak ve kas gücünü artırmak için sıkça kullanılan destekleyici tedavi yöntemleridir. Ayrıca, sağlıklı bir yaşam tarzı ve dengeli beslenme, romatizma semptomlarını yönetmede ve genel sağlığı iyileştirmede büyük önem taşır. Ebeveynler, çocuklarının belirtilerini yakından izlemeli ve doktorun önerdiği tedavi planını takip etmelidir. Çocuk romatizma uzmanları, çocukların sağlıklarını ve günlük yaşamlarını en iyi şekilde sürdürebilmeleri için ailelere rehberlik eder ve destek sağlar.`},

  epigenetikdanismanlik: {title:"Epigenetik Danışmanlık",
    imgSrc:'/assets/images/epigenetikdanismanlik.webp',
    description:`Epigenetik danışmanlık, bireylerin genetik bilgileri ve yaşam tarzları arasındaki etkileşimi anlayarak, sağlıklı yaşam biçimleri geliştirmelerine yardımcı olan bir alan olarak tanımlanabilir. Epigenetik, genlerin çevresel faktörler ve yaşam tarzı seçimleriyle nasıl açılıp kapandığını inceler. Bu danışmanlık, kişisel sağlık ve hastalık risklerini yönetmede önemli bir rol oynar, çünkü bireylerin genetik yatkınlıkları ve çevresel maruziyetleri göz önüne alınarak özelleştirilmiş sağlık önerileri sunar.

    Epigenetik danışmanlık sürecinde, bireylerin genetik bilgileri ve sağlık geçmişi detaylı bir şekilde analiz edilir. Danışmanlar, bireylerin diyet, egzersiz, uyku düzeni ve stres yönetimi gibi yaşam tarzı faktörlerini değerlendirir. Bu değerlendirmeler sonucunda, epigenetik danışmanlar kişiye özel önerilerde bulunur. Örneğin, belirli bir diyet planı, vitamin ve mineral takviyeleri, egzersiz programları ve stres azaltma teknikleri gibi öneriler sunulabilir. Amaç, gen ekspresyonunu olumlu yönde etkileyerek, bireylerin sağlıklarını optimize etmektir.
    
    Epigenetik danışmanlık, hastalıkların önlenmesi ve yönetilmesinde de önemli bir rol oynar. Bireylerin genetik yatkınlıklarına göre özelleştirilmiş sağlık stratejileri, kronik hastalıkların riskini azaltabilir ve genel sağlığı iyileştirebilir. Örneğin, aile geçmişinde kalp hastalığı olan bir birey, epigenetik danışmanlık sayesinde uygun diyet ve egzersiz programları ile risklerini minimize edebilir. Ayrıca, kanser, diyabet ve obezite gibi yaygın hastalıkların yönetiminde de epigenetik danışmanlık önemli katkılar sağlar. Epigenetik danışmanlar, bireylerin sağlık hedeflerine ulaşmaları ve daha uzun, sağlıklı bir yaşam sürmeleri için gereken bilgileri ve desteği sağlar.`},

  fonksiyoneltip: {title:"Fonksiyonel Tıp",
    imgSrc:'/assets/images/fonksiyoneltip.webp',
    description:`Fonksiyonel tıp, bireylerin sağlık sorunlarını ele alırken vücudun bütünsel işleyişini dikkate alan, kişiye özel bir sağlık yaklaşımıdır. Bu tıp dalı, hastalıkların kökenine inerek, genetik, biyokimyasal, çevresel ve yaşam tarzı faktörlerinin nasıl etkileşime girdiğini anlamaya çalışır. Fonksiyonel tıpta, her bireyin biyokimyası ve genetiği farklı olduğu için tedavi ve önleme stratejileri de kişiselleştirilir.

    Fonksiyonel tıp uygulamaları, kapsamlı bir sağlık değerlendirmesiyle başlar. Bu değerlendirme, detaylı tıbbi geçmiş, laboratuvar testleri ve yaşam tarzı analizlerini içerir. Fonksiyonel tıp doktorları, hastalık belirtilerini sadece baskılamak yerine, bu belirtilerin altta yatan nedenlerini araştırır. Örneğin, kronik yorgunluk şikayeti olan bir bireyin enerji düzeylerini etkileyen hormonal dengesizlikler, besin eksiklikleri veya çevresel toksinler gibi faktörler incelenir. Bu kapsamlı yaklaşım, hastalıkların önlenmesi ve tedavisinde daha etkili stratejiler geliştirilmesini sağlar.
    
    Fonksiyonel tıpta tedavi planları, genellikle beslenme değişiklikleri, egzersiz, stres yönetimi ve doğal takviyeler gibi yaşam tarzı müdahalelerini içerir. Ayrıca, bağırsak sağlığı, detoksifikasyon, hormonal denge ve bağışıklık sistemi desteği gibi spesifik alanlarda da kişiselleştirilmiş tedaviler uygulanır. Örneğin, inflamatuar bağırsak hastalığı olan bir birey için anti-inflamatuar bir diyet, probiyotikler ve sindirim enzimleri önerilebilir. Fonksiyonel tıp, hastaların sağlıklarını optimize etmelerine, kronik hastalık risklerini azaltmalarına ve genel yaşam kalitelerini artırmalarına yardımcı olmayı hedefler. Bu bütüncül ve kişiye özel yaklaşım, fonksiyonel tıbbın modern sağlık bakımında giderek daha fazla tercih edilmesine neden olmaktadır.`},

  fitoterapi: {title:"Fitoterapi",
    imgSrc:'/assets/images/fitoterapi.webp',
    description:`Fitoterapi, bitkisel ilaçların kullanımıyla sağlık sorunlarını önleme ve tedavi etme yöntemidir. Bu terapi yöntemi, binlerce yıllık bir geçmişe dayanır ve günümüzde modern tıpla birlikte kullanılmaktadır. Fitoterapi, bitkilerin doğal bileşenlerinin sağlık üzerindeki olumlu etkilerini araştırarak, hastalıkların tedavisinde etkili ve güvenli alternatifler sunar. Bitkisel ilaçlar, çeşitli formlarda hazırlanabilir, bunlar arasında çaylar, tentürler, kapsüller, merhemler ve uçucu yağlar bulunur.

    Fitoterapi, vücudun kendi iyileşme yeteneğini desteklemeye odaklanır ve genellikle kronik hastalıkların yönetiminde kullanılır. Örneğin, anti-inflamatuar özellikleriyle bilinen zerdeçal, romatizma ve artrit gibi iltihaplı hastalıkların tedavisinde kullanılabilir. Papatya ve lavanta gibi bitkiler ise sakinleştirici etkileriyle stres ve anksiyeteyi azaltmada yardımcı olabilir. Fitoterapide kullanılan bitkiler, genellikle belirli sağlık sorunlarına yönelik seçilir ve kişiye özel tedavi planları oluşturulur.
    
    Fitoterapinin etkili olabilmesi için, bitkilerin doğru dozda ve uygun şekilde kullanılması önemlidir. Bitkisel tedavilerin tıbbi gözetim altında uygulanması, olası yan etkilerin ve bitkisel ilaç-etkileşimlerinin önlenmesine yardımcı olur. Eğitimli fitoterapistler, bitkilerin farmakolojik etkilerini, vücuttaki etkileşimlerini ve güvenli kullanımını bilirler. Bu uzmanlar, kişisel sağlık geçmişine ve mevcut durumuna göre en uygun bitkisel tedavileri önerirler.
    
    Sonuç olarak, fitoterapi, doğal ve bütüncül bir tedavi yaklaşımı sunarak, modern tıbbın yanında tamamlayıcı bir rol oynar. Doğru uygulandığında, fitoterapi, hastalıkların semptomlarını hafifletmede, bağışıklık sistemini güçlendirmede ve genel sağlığı iyileştirmede önemli katkılar sağlayabilir. Bu doğal tedavi yöntemi, hastaların yaşam kalitesini artırmayı ve uzun vadede sağlıklı kalmayı hedefler.`},

  ayurvedikdanismanlik: {title:"Ayurvedik Danışmanlık",
    imgSrc:'/assets/images/ayurvedikdanismanlik.webp',
    description:`Ayurvedik danışmanlık, binlerce yıllık bir geçmişe sahip olan Ayurveda tıbbının prensiplerine dayalı olarak bireylerin sağlık ve yaşam kalitesini iyileştirmeyi amaçlayan bir yaklaşımdır. Ayurveda, Hindistan kökenli bir sağlık sistemidir ve insan vücudunun, zihnin ve ruhun birbiriyle dengede olmasının önemini vurgular. Ayurvedik danışmanlık, kişiye özel sağlık planları oluşturmak için bireyin fiziksel, zihinsel ve duygusal durumunu değerlendirir.

    Ayurvedik danışmanlık sürecinde, bireyin dosha tipini belirlemek temel bir adımdır. Ayurveda, üç temel dosha (Vata, Pitta ve Kapha) teorisine dayanır. Her bireyin bu üç doshanın bir kombinasyonuna sahip olduğuna inanılır ve bu doshaların dengede olması sağlıklı bir yaşam için gereklidir. Danışmanlar, kişinin yaşam tarzı, beslenme alışkanlıkları, uyku düzeni ve stres seviyeleri gibi faktörleri analiz ederek dosha dengesizliklerini tespit ederler. Bu değerlendirmeler sonucunda, kişiye özel diyet, egzersiz, bitkisel ilaçlar ve meditasyon teknikleri gibi önerilerde bulunurlar.
    
    Ayurvedik danışmanlık, bütüncül bir sağlık yaklaşımını benimser ve doğal tedavi yöntemlerini teşvik eder. Danışmanlık sürecinde, bireylerin kendilerini daha iyi anlamaları ve kendi sağlıklarını yönetme becerilerini geliştirmeleri amaçlanır. Beslenme, Ayurvedik danışmanlığın önemli bir bileşenidir. Örneğin, Vata doshası dengesi için sıcak, yağlı ve baharatlı yiyecekler önerilirken, Pitta dengesizliği olanlara serinletici ve yatıştırıcı yiyecekler tavsiye edilir. Kapha dengelemekte ise hafif ve baharatlı yiyecekler kullanılır. Ayrıca, bitkisel tedaviler, Ayurvedik yağ masajları (abhyanga), yoga ve nefes teknikleri (pranayama) gibi uygulamalar da Ayurvedik danışmanlığın bir parçasıdır.
    
    Sonuç olarak, Ayurvedik danışmanlık, bireylerin sağlıklarını doğal ve bütüncül yöntemlerle iyileştirmelerine yardımcı olur. Bu yaklaşım, bedenin kendi kendini iyileştirme kapasitesini desteklerken, bireylerin yaşam tarzlarını ve alışkanlıklarını olumlu yönde değiştirmelerini sağlar. Ayurvedik danışmanlık, uzun vadede sağlık ve dengeyi koruyarak, bireylerin daha mutlu ve sağlıklı bir yaşam sürmelerine katkıda bulunur.`},

  quexedkuantumdanismanlik: {title:"QUEX ED Kuantum Danışmanlık", 
    imgSrc:'/assets/images/quexedkuantumdanismanlik.webp', 
    description:`QUEX ED Quantum danışmanlık, bireylerin sağlık ve iyilik hallerini iyileştirmek amacıyla ileri teknoloji ve kuantum biyofeedback yöntemlerini kullanan bir danışmanlık hizmetidir. QUEX ED cihazı, vücuttaki biyolojik frekansları analiz ederek, enerji dengesizliklerini ve stres faktörlerini tespit eder. Bu bilgiler doğrultusunda, kişiye özel tedavi ve iyileştirme planları oluşturulur. Bu teknoloji, kuantum fiziği ve biyolojik geri bildirim prensiplerine dayalı olarak çalışır ve vücudun doğal iyileşme sürecini destekler.

    Danışmanlık sürecinde, ilk olarak bireyin sağlık durumu ve yaşam tarzı hakkında kapsamlı bir değerlendirme yapılır. QUEX ED cihazı, bireyin biyolojik frekanslarını tarar ve bu verileri analiz eder. Bu analiz, bireyin fiziksel, zihinsel ve duygusal durumunu değerlendirmek için kullanılır. Biyofeedback yöntemiyle, enerji akışındaki blokajlar, stres faktörleri ve diğer dengesizlikler tespit edilir. Bu veriler, bireyin genel sağlık durumunu iyileştirmek ve optimal dengeyi sağlamak için kullanılır.
    
    QUEX ED Quantum danışmanlık, kişiye özel tedavi planları oluşturur. Bu planlar, enerji dengesizliklerini düzeltmeye, stresi azaltmaya ve genel sağlık ve iyilik halini artırmaya yönelik stratejiler içerir. Tedavi süreçleri arasında beslenme önerileri, stres yönetimi teknikleri, enerji terapileri ve yaşam tarzı değişiklikleri bulunabilir. QUEX ED cihazı, düzenli aralıklarla yapılan biyofeedback seansları ile vücudun tepkilerini izler ve iyileşme sürecini optimize eder.
    
    Sonuç olarak, QUEX ED Quantum danışmanlık, bireylerin sağlıklarını ve yaşam kalitelerini artırmak için ileri teknoloji ve kuantum biyofeedback yöntemlerini bir araya getirir. Bu bütüncül ve kişiye özel yaklaşım, bireylerin enerji dengesizliklerini düzeltmelerine, stres faktörlerini azaltmalarına ve genel sağlıklarını iyileştirmelerine yardımcı olur. QUEX ED danışmanlık hizmeti, modern tıbbın yanında tamamlayıcı bir rol oynayarak, bireylerin sağlıklı ve dengeli bir yaşam sürmelerine katkıda bulunur.`}
}

const Container = tw.div`relative`;
const Heading = tw(SectionHeading)`w-full`
const ColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const StyledImage = styled.img`
  ${tw`w-full md:w-full object-cover object-center mx-auto md:my-8 rounded-lg`}
`;
const ParagraphDiv = tw.div`mt-4  text-center md:text-left`

const DecoratorBlob1 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute left-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none absolute bottom-0 right-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const Services = () => {
    const param = useParams();
    console.log(param)

  return (
    <Container>
      <ScrollToTop/>
      <ColumnContainer>
        <Heading>{servicesList[param.id].title}</Heading>
        <StyledImage src={servicesList[param.id].imgSrc} alt="" />
        <DecoratorBlob1/>
        <DecoratorBlob2/>
        {servicesList[param.id].description.split("\n").map((i,key) => {
            return <ParagraphDiv key={key}>{i}</ParagraphDiv>;
        })}
      </ColumnContainer>
    </Container>
  );
};

export default Services;
import React, { useState, useRef, useEffect } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

const DropdownContainer = styled.div`
  ${tw`relative inline-block text-left z-50`}
`;

const DropdownButton = styled.button`
  ${tw`text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`}
  background: none;
  border: none;
  cursor: pointer;
`;

// const DropdownMenu = styled.div`
//   ${tw`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50`}
// `;
const DropdownMenu = styled.div`
  ${tw`origin-top absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50`}
  left: 50%;
  transform: translateX(-50%);
`;

// const DropdownItem = styled(RouterNavLink)`
//   ${tw`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 hover:text-gray-900`}
// `;

const DropdownItem = styled(RouterNavLink)`
  ${tw`block px-2 py-2 text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`}
  background: none;
  border: none;
  cursor: pointer;
`;

const Dropdown = ({ label, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        {label}
        {/* <svg
          className="ml-2 -mr-1 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d={isOpen ? 'M5.292 12.707a1 1 0 011.414 0L10 16.002l3.293-3.295a1 1 0 011.414 1.415l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.415z' : 'M5.292 7.707a1 1 0 011.414 0L10 11.002l3.293-3.295a1 1 0 011.414 1.415l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.415z'}
            clipRule="evenodd"
          />
        </svg> */}
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {items.map((item, index) => (
            <DropdownItem key={index} to={item.to}>
              {item.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;

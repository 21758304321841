import React from 'react';
import "../style.css";
import "tailwindcss/lib/css/preflight.css";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Hero from "../components/hero/TwoColumnWithFeaturesAndTestimonial";
import Services from "../components/features/DashedBorderSixFeatures"
import Blog from "../components/blogs/ThreeColSimpleWithImage"

const MainPage = () => {
  return (
    <AnimationRevealPage>
    <Hero></Hero>
    <Services></Services>
    <Blog></Blog>
  </AnimationRevealPage>
  );
};

export default MainPage;

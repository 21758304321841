import Blog from 'pages/Blog';
import MainPage from 'pages/Main';
import About from 'pages/About'
import Contact from 'pages/Contact'
import Services from 'pages/Services'
import { Routes ,Route } from 'react-router-dom';

export const Navigation = () => {
    return(
        <Routes>
            <Route path='/' element={<MainPage/>} />
            <Route path='/blog' element={<Blog/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/services/:id' element={<Services/>} />
        </Routes>
    );
}
import { NavLink as RouterNavLink } from 'react-router-dom';
import tw from 'twin.macro';
import styled from 'styled-components';

const StyledLogoLink = styled(RouterNavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  color: #6a24ff;

  img {
    ${tw`w-10 mr-3`}
  }
`;

export default StyledLogoLink;
